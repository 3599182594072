@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=KoHo:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'KoHo', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar
{
  width: 6px;
}

::-webkit-scrollbar-thumb
{
  background: linear-gradient(transparent, #5738DD);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover
{
  background: linear-gradient(transparent, #A938DD);
}

.bg-card {
  background: linear-gradient(160.35deg, rgba(255, 255, 255, 0.25) -9.19%, rgba(174, 159, 239, 0.128906) 64.09%, rgba(87, 56, 221, 0.05) 111.06%);
  
  backdrop-filter: blur(6px);
}
 
.btn {
  --clr-font-main: hsla(0 0% 20% / 100);
  --btn-bg-1: #A938DD;
  --btn-bg-2: #5738DD;
  --btn-bg-3: #04020B;
  --btn-bg-color: #ffffff;
  --radii: 0.5em;
  cursor: pointer;
  font-size: var(--size, 1rem);
  font-family: "KoHo", "Segoe UI", system-ui, sans-serif;
  font-weight: 500;
  transition: 0.5s;
  background-size: 280% auto;
  background-image: linear-gradient(325deg, var(--btn-bg-2) 0%, var(--btn-bg-1) 55%, var(--btn-bg-2) 90%);
  color: var(--btn-bg-color);
}

.btn:hover {
  background-position: right top;
}

.btn:is(:focus, :focus-within,:active) {
  outline: none;
  box-shadow: 0 0 0 2px var(--btn-bg-3), 0 0 0 4px var(--btn-bg-2);
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: linear;
  }
}


.nav-ul{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  -ms-border-radius: 32px;
  -o-border-radius: 32px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.25);
}

.nav-li{
  list-style: none;
}

.nav-a{
  position: relative;
  color: #ffffff;
  text-decoration: none;
  display: inline-block;
  padding: 20px 30px;
  z-index: 1000;
  backdrop-filter: blur(15px);
}

.icon{
  font-size: 1.5em;
}

.icon:hover{
  opacity: 1;
}

#marker {
  position: absolute;
  top: 0;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  z-index: 1;
}

#marker::before{
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  width: 50px;
  height: 40px;
  border-radius: 8px;
  background: #5738DD;
  box-shadow: 0 0 15px #5738DD,  0 0 30px #5738DD,  0 0 45px #5738DD  0 0 60px #5738DD;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
